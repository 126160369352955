import React from 'react';
import { FaGithub, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

const CopyRight = () => {
	return (
		<>
			<div className="copy-right">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="copy-text">
								<p>©2023 Datalium. All Rights Reserved</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="copy-icon text-lg-right">
								<ul>
									<li>
										<a
											href="https://github.com/datalium/"
											target="_blank"
											rel="noreferrer"
										>
											<i>
												<FaGithub />
											</i>
										</a>
									</li>
									<li>
										<a
											href="https://twitter.com/dataliuminc/"
											target="_blank"
											rel="noreferrer"
										>
											<i>
												<FaTwitter />
											</i>
										</a>
									</li>
									<li>
										<a
											href="https://www.linkedin.com/company/datalium"
											target="_blank"
											rel="noreferrer"
										>
											<i>
												<FaLinkedinIn />
											</i>
										</a>
									</li>
									<li>
										<a
											href="https://www.youtube.com/@datalium"
											target="_blank"
											rel="noreferrer"
										>
											<i>
												<FaYoutube />
											</i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CopyRight;
