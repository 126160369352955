//import React, { useState } from 'react';

const Cta = () => {
	//const FORM_URL = `https://app.convertkit.com/forms/5209405/subscriptions`;

	//const [email, setEmail] = useState('');
	//alert('email: ' + email + ' submitted');

	const handleSubmit = async (event) => {
		event.preventDefault();
		// if (email !== '') {
		// 	return (
		// 		<div>
		// 			<h2>Thank you for subscribing!</h2>
		// 		</div>
		// 	);
		// }
		// alert('email: ' + email + ' submitted');

		// const data = new FormData(event.target);
		// data.append('email_address', email);

		// try {
		// 	const response = await fetch(FORM_URL, {
		// 		method: 'POST',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		body: data,
		// 	});
		// 	alert(JSON.stringify(response));

		// 	const json = await response.json();
		// 	alert(JSON.stringify(json));

		// 	if (json.status === 'success') {
		// 		return;
		// 	} else {
		// 		alert('An error occured, please try again later.');
		// 	}
		// } catch (error) {
		// 	alert(error);
		// }

		// return (
		// 	<div>
		// 		<h2>Thank you for subscribing!</h2>
		// 	</div>
		// );
	};

	// const handleEmailChange = (event) => {
	// 	const { value } = event.target;
	// 	setEmail(value);
	// };

	return (
		<>
			<section id="cta-area" className="py100">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="cta-search-area">
								<form
									id="subscribe-form"
									onSubmit={handleSubmit}
								>
									<div className="input-group">
										<input
											type="text"
											name="email"
											className="form-control"
											placeholder="Type Your Email Address.."
											// onChange={handleEmailChange}
										/>
										<div className="input-group-append">
											<button
												className="btn btn-cta"
												type="submit"
											>
												Subscribe Now
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="news-letter-text pl40 pr80 responsive-mt-30">
								{/* <h2 className="white-color">Newsletter</h2> */}
								<h2 className="white-color">
									Subscribe Our Newletter
								</h2>
								<p className="white-color">
									Subscripe to get the latest content by email
									and stay updated with our news.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Cta;
