export const MenuData = [
	// {
	// 	name: 'Home',
	// 	href: '/',
	// 	has_children: false,
	// },
	// {
	// 	name: 'About Us',
	// 	href: '/about',
	// 	has_children: false,
	// },
	// {
	// 	name: 'Services',
	// 	href: '/services',
	// 	has_children: false,
	// },
	// {
	// 	name: 'Projects',
	// 	href: '/projects',
	// 	has_children: false,
	// },
];
