import React from 'react';
import { MdPermPhoneMsg } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { BiMailSend } from 'react-icons/bi';

import { EmailBase64, PhoneBase64 } from 'hidden-from-bots-react';

const ContactInfo = () => {
	return (
		<>
			<div className="contact-content">
				<h3>Let us know how we can help</h3>
				<p>
					Please provide some information on your project or goals and
					we'll be in touch shortly
				</p>
				<ul className="contact-info">
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<MdPermPhoneMsg />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5>
									<PhoneBase64 phone="MTg4ODczMzkwMzA=">
										1-888-733-9030
									</PhoneBase64>
								</h5>
							</div>
						</div>
					</li>
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<ImLocation2 />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5>340 Simcoe Ave, Mont-Royal, QC H3P 1X2</h5>
							</div>
						</div>
					</li>
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<BiMailSend />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5>
									<EmailBase64 email="aW5mb0BkYXRhbGl1bS5jb20=">
										info@datalium.com
									</EmailBase64>
								</h5>
								{/* <h5>
									<a href="mailto:demo@mail.com">
										sales@datalium.com
									</a>
								</h5> */}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default ContactInfo;
