import React from 'react';

import FormInput from '../From/index';

import { useForm } from '@formspree/react';

const ContactForm = () => {
	const [state, handleSubmit] = useForm('xayzzpgq');

	if (state.succeeded) {
		return (
			<div className="contact-form-area box-shadow">
				<h2>Thank you for contacting us!</h2>
				<p>
					We appreciate you contacting us. One of our colleagues will
					get back in touch with you soon.
				</p>
				<p>!Have a great day!</p>
			</div>
		);
	}

	return (
		<>
			<div className="contact-form-area box-shadow">
				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-lg-6  col-md-12 col-sm-12 col-12">
							<FormInput
								tag={'input'}
								type={'text'}
								name={'firstName'}
								classes={'form-control'}
								placeholder={'First Name *'}
							/>
						</div>
						<div className="col-lg-6  col-md-12 col-sm-12 col-12">
							<FormInput
								tag={'input'}
								type={'text'}
								name={'lastName'}
								classes={'form-control'}
								placeholder={'Last Name *'}
							/>
						</div>
						<div className="col-lg-12  col-md-12 col-sm-12 col-12">
							<div className="form-group">
								<FormInput
									tag={'input'}
									type={'email'}
									name={'email'}
									classes={'form-control'}
									placeholder={'Enter your email here....'}
								/>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="form-group">
								<FormInput
									tag={'textarea'}
									name={'message'}
									classes={'form-control'}
									placeholder={'Write Your Message *'}
								/>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="form-submit-button">
								<FormInput
									tag={'button'}
									val={'Send Message'}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default ContactForm;
