import React from 'react';

// Modal
//import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';

// Import Banner Img
//import img_banner from '../../../assets/img/about/1.png';

// Icon
//import { AiOutlinePlayCircle } from 'react-icons/ai';

// Import React Dom
import { Link } from 'react-router-dom';

const Banner = () => {
	//const [isOpen, setOpen] = useState(false);
	return (
		<React.Fragment>
			{/* Banner Start */}
			<section id="banner-five">
				<div className="bg-shape"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="home-one-banner-content">
								<h1 className="color-white">
									Data is the most precious element
								</h1>
								<h2 className="color-white">
									Modern Data Analytics and ML/AI Solutions
								</h2>
								{/* <p className="color-white">
									Real-time data management technologies,
									designing and implementing data-centric
									solutions for your business.
								</p> */}
								<div className="banner-one-btn pt30">
									<Link to="/#" className="btn btn-theme">
										Get Started
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Banner End */}
		</React.Fragment>
	);
};

export default Banner;
