import React from 'react';
import CommonBanner from '../component/Common/Banner';
import ContactDetails from '../component/Contact';
import Cta from '../layout/Cta';

const Contact = () => {
	return (
		<>
			<CommonBanner heading="Let's talk" menu1="Home" menu2="Contact" />
			<ContactDetails />
			<Cta />
		</>
	);
};

export default Contact;
