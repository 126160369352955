import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	BrowserRouter,
} from 'react-router-dom';

// All Page Component
import Home from '../src/page/Home';
import Services from '../src/page/Services';
import About from '../src/page/About';
import Projects from '../src/page/Projects';
import Contact from '../src/page/Contact';
import Faqs from '../src/page/Faqs';
import Error from '../src/page/Error';

// Scroll Top
import ScrollToTop from './helpers/ScrollToTop';

// Layout Component
import Header from '../src/layout/Header';
//import Footer from '../src/layout/Footer';
//import Cta from '../src/layout/Cta';
import CopyRight from '../src/layout/CopyRight';

const App = () => {
	return (
		<>
			<BrowserRouter>
				<Router>
					<ScrollToTop>
						<Header />
						<Switch>
							<Route path="/" exact component={Home} />
							<Route
								path="/services"
								exact
								component={Services}
							/>
							<Route path="/about" exact component={About} />
							<Route
								path="/projects"
								exact
								component={Projects}
							/>
							<Route path="/contact" exact component={Contact} />
							<Route path="/faqs" exact component={Faqs} />
							<Route exact component={Error} />
						</Switch>
						{/* <Cta /> */}
						{/* <Footer /> */}
						<CopyRight />
					</ScrollToTop>
				</Router>
			</BrowserRouter>
		</>
	);
};

export default App;
